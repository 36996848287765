/**
 *	Smart app banner
 */

(function ($) {

    "use strict";

    var self;

    ori.addModule("smartAppBanner", {

        /**
         * Initialization.
         */
        init: function () {
            initEvents();            
        }
    });

    function initEvents() {
        if (window.addEventListener) {

            window.addEventListener("beforeinstallprompt", function(e) {
                onBannerAppeared();

                if (e && e.userChoice) {
                    e.userChoice.then(function (choiceResult) {
                        if (!choiceResult || typeof choiceResult.outcome === "undefined") {
                            return;
                        }

                        if (choiceResult.outcome === "dismissed") {
                            onBannerDismissed();
                        } else {
                            onBannerAccepted(choiceResult.outcome);
                        }                        
                    });
                }
            });
        }
    }

    function onBannerAppeared() {
        pushDataLayer("SAB PopUp");          
    }

    function onBannerAccepted(outcome) {
        pushDataLayer("SAB " + outcome);      
    }

    function onBannerDismissed() {
        pushDataLayer("SAB close");        
    }

    function getAppId() {
        var appName = "",
            ix,
            idPattern = "id=",
            manifest = $("link[rel=manifest]").attr("href");

        if (manifest) {
            ix = manifest.indexOf(idPattern);
            appName = manifest.substr(ix + idPattern.length);
        }

        return appName;
    }

    function getConsultantNumber() {
        return $.cookie("UserKey");
    }

    function pushDataLayer(eventName) {
        if (typeof ori.dataLayer !== "undefined") {
            ori.dataLayer.push({
                "Event": eventName,
                "Application ID": getAppId(),
                "Consultant ID": getConsultantNumber()
            });
        }      
    }

    self = ori.smartAppBanner;

})(jQuery);


